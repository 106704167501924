import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 팝업상세
 */
class PopupDetail {
    /**
     * 생성자
     */
    constructor() {
    }

    /**
     * 파일 상세 정보 조회
     * @param {int} seq 
     * @param {String} systcode 
     * @returns 
     */
     getPopupInfoSelect(seq, systcode) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz';
            request.methodId = 'GetPopupInfoSelect';
            request.parameters.seq = seq
            request.parameters.systcode = systcode

            service.execute(request).then(response => {

                const tables = response.data.result.value;
                
                if(!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 팝업정보 등록
     * @param {Any} detail 
     * @returns 
     */
    savePopupInfo(detail) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz'
            request.methodId = 'CreatePopupInfo'
            request.parameters = detail

            service.execute(request).then(response => {

                resolve(response.data)
            }).catch(error => reject(error));
        });
    }

    /**
     * 팝업정보 수정
     * @param {Any} detail 
     * @returns 
     */
    updatePopupInfo(detail) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz'
            request.methodId = 'UpdatePopupInfo'
            request.parameters = detail

            service.execute(request).then(response => {

                resolve(response.data)
            }).catch(error => reject(error));
        });
    }

    /**
     * 팝업정보 삭제
     * @param {Any} detail 
     * @returns 
     */
    deletePopupInfo(detail) {
        return new Promise((resolve, reject) => {
            
            const url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.FilePathBiz'
            request.methodId = 'DeletePopupInfo'
            request.parameters = detail
            
            service.execute(request).then(response => {

                resolve(response.data)
            }).catch(error => reject(error));
        });
    }
}

export const popupDetail = new PopupDetail();