<template>
    <div class="admin-content">
        <h3>팝업관리</h3>
        <div class="flex-wrap right">
            <div class="btn-wrap">
                <button v-if="state.dataTable.isEdit" class="btn-box gray" @click="methods.updatePopupInfo">수정</button>
                <button v-else class="btn-box green" @click="methods.savePopupInfo">저장</button>
                <button v-if="state.dataTable.isEdit" class="btn-box red" @click="methods.deletePopupInfo" >삭제</button>
                <button class="btn-box" autofocus @click="methods.getPopupInfoList" >취소</button>
            </div>
        </div>
        <div class="table-wrap table-list none-scroll-y mt50">
			<div class="table-box">
				<table>
					<colgroup>
						<col width="15%">
						<col width="85%">
					</colgroup>
					<tbody>
						<tr>
							<th scope="row">팝업명 <i class="required">*</i></th>
							<td><input class="input-text mr10" id="popupName" ref="popupName" type="text" v-model.trim="state.dataTable.popupDetailItems.PopupName" :disabled="state.dataTable.isEdit" maxlength="128" /></td>
						</tr>
						<tr>
							<th scope="row">팝업설명 <i class="required">*</i></th>
							<td><input class="input-text mr10" id="description" ref="description" type="text" v-model.trim="state.dataTable.popupDetailItems.Description" maxlength="256" /></td>
						</tr>
						<tr>
							<th scope="row">팝업경로 <i class="required">*</i></th>
							<td><input class="input-text mr10" id="popupPath" ref="popupPath" type="text" v-model.trim="state.dataTable.popupDetailItems.PopupPath" maxlength="1000" /></td>
						</tr>
						<tr>
							<th scope="row">유지기간</th>
							<td><input type="date" id="icon" ref="contentMaintain" class="input-text mr10" v-model="state.dataTable.popupDetailItems.ContentMaintain" :showIcon="true"/></td>
						</tr>
						<tr>
							<th scope="row">사용여부</th>
							<td>
								<div class="select-box">
									<select id="selectUseUn" v-model="state.dataTable.popupDetailItems.UseYn">
										<option value="Y">Y</option>
										<option value="N">N</option>
									</select>
								</div>
							</td>
						</tr>
						<tr>
							<th>파일첨부</th>
							<td>
								<div>
									<FileUploader ref="fileUploader" :subUrl="store.getters.boardSubDirectoryPath" :attachments="state.fileItems" :maxFileCount="1"/>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
    </div>
</template>
<script>
import { reactive, ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { popupDetail } from '@/modules/system/PCM_SM0651E.js';
import { useStore } from 'vuex';
import router from '@/router'
import alertManager from '@/utils/alert-confirm-manager';
import FileUploader from '@/components/FileUploader.vue';
import { useRoute } from 'vue-router';
export default ({
    name: 'PCM_SM0651E',
    components: { FileUploader },
    setup() {

        // 라우트
        const vrt = useRoute();

        // 스토어
        const store = useStore();

        // 유효성 검사 ref
        const popupName = ref(); // 팝업명
        const description = ref(); // 팝업설명
        const popupPath = ref(); // 팝업경로
        const contentMaintain = ref(); // 유지기간

        // state
        const state = reactive({

            // 첨부파일 목록
            fileItems: new Array(),

            // 데이터 테이블 state
            dataTable: {

                // 팝업 상세 정보
                popupDetailItems: new Object(),

                // 수정여부
                isEdit: false,
            },
        })

        // 파일 업로더
        const fileUploader = ref(null);

        // 메서드
        const methods = {
            // 목록으로 돌아가기
            getPopupInfoList: () => {
                router.push({ path: '/PCM_SM0650E' });
            },

            // 팝업정보 등록
            savePopupInfo: () => {

                if (methods.popupValidation()) {
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);
    
                    // 파일업로드 실행
                    fileUploader.value.uploadFiles().then(response => {
    
                        // 파일 json 추가
                        let fileArr = new Array();
                        if (response.data.files.length > 0) {
    
                            fileArr = {
                                "FileSeq": "",
                                "FileNm": response.data.files[0]["name"],
                                "FilePath": response.data.files[0]["filePath"],
                                "FileSize": response.data.files[0]["size"],
                                "FileType": response.data.files[0]["mimeType"],
                                "IsContent": "N",
                                "IsThumb": "N",
                                "UseYn": "Y"
                            }
                        }
                        
                        state.dataTable.popupDetailItems.File = JSON.stringify(fileArr);
                        popupDetail.savePopupInfo(state.dataTable.popupDetailItems).then((response) => {
    
                            if (response.success) {
    
                                alertManager.toast.add();
                                methods.getPopupInfoList();
                            }
    
                        }).finally(() => {
    
                            // 스토어 값 변경
                            store.commit("setLoadingStatus", false);
                        });
                    })
                }
            },

            // 팝업정보 수정
            updatePopupInfo: () => {

                if (methods.popupValidation()) {
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);

                    // 파일업로드 실행
                    fileUploader.value.uploadFiles().then(response => {

                        // 파일 json 추가
                        let fileArr = null;
                        if (response.data.files.length) {

                            fileArr = {
                                "FileSeq": "",
                                "FileNm": response.data.files[0]["name"],
                                "FilePath": response.data.files[0]["filePath"],
                                "FileSize": response.data.files[0]["size"],
                                "FileType": response.data.files[0]["mimeType"],
                                "IsContent": "N",
                                "IsThumb": "N",
                                "UseYn": "Y"
                            }
                        }
                        else {

                            if (!state.fileItems.length) fileArr = {};
                        }
                        state.dataTable.popupDetailItems.File = !!fileArr ? JSON.stringify(fileArr) : null;

                        popupDetail.updatePopupInfo(state.dataTable.popupDetailItems).then((response) => {

                            if (response.success) {

                                alertManager.toast.save();
                            }

                        }).finally(() => {

                            // 스토어 값 변경
                            store.commit("setLoadingStatus", false);
                        });
                        methods.getPopupInfoList();
                    }).catch(error => {

                        console.log(error)

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    });
                }
            },

            // 팝업정보 삭제
            deletePopupInfo: () => {

                alertManager.confirm.remove().then(() => {
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);

                    popupDetail.deletePopupInfo(state.dataTable.popupDetailItems).then((response) => {

                        if (response.success) {

                        }

                    }).finally(() => {

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    });
                    methods.getPopupInfoList();
                })
            },

            // 유효성 검사
            popupValidation: () => {

                let validator = state.dataTable.popupDetailItems;
                // 팝업명
                if (!validator.PopupName) {

                    popupName.value.focus();
                    alertManager.alert.warning("팝업명을 입력해주세요.");
                    return false;
                }
                // 팝업설명
                else if (!validator.Description) {

                    description.value.focus();
                    alertManager.alert.warning("팝업설명을 입력해주세요.");
                    return false;
                }
                // 팝업경로
                else if (!validator.PopupPath) {

                    popupPath.value.focus();
                    alertManager.alert.warning("팝업경로를 입력해주세요.");
                    return false;
                }
                // 유지기간
                else if (!validator.ContentMaintain) {

                    contentMaintain.value.focus();
                    alertManager.alert.warning("유지기간을 입력해주세요.");
                    return false;
                }
                else {
                    return true;
                }
            },
        }

        // 이벤트 핸들러
        const eventHandler = {
            dataTable: {
                // DateTime 변환 이벤트 처리
                dateTimeFormat: (value) => {
                    const dayjs = require('dayjs')
                    state.dataTable.popupDetailItems.ContentMaintain = dayjs(value).format('YYYY-MM-DD')
                },
            },
        }

        onMounted(() => {

            // 신규등록
            if (vrt.query.NewInsert === "Y") {
                state.dataTable.popupDetailItems = new Object();
                state.dataTable.popupDetailItems.SystCode = vrt.query.SystCode;
                state.dataTable.popupDetailItems.UseYn = "Y";
                state.dataTable.isEdit = false;
            }
            // 수정
            else {
                popupDetail.getPopupInfoSelect(vrt.query.Seq, vrt.query.SystCode).then(result => {

                    // 팝업 상세 정보
                    if (result.Table != null && result.Table.rows.length > 0) {

                        state.dataTable.popupDetailItems = result.Table.rows[0];
                    }

                    // 파일 정보
                    if (result.Table1 != null) {

                        state.fileItems = result.Table1.rows;
                    }
                });
                state.dataTable.isEdit = true;
            }
        })

        return {
            state,
            popupDetail,
            methods,
            eventHandler,
            store,
            fileUploader,
            popupName,
            description,
            popupPath,
            contentMaintain,
        }
    },
})
</script>
